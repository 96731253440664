import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import Features from '../components/shared/Features';
import Hero from '../components/shared/hero';

const Home = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        margin: 0,
        padding: 0,
        width: '100%',
      }}
    >
      <CssBaseline />
      <Hero />
      <Features />
    </Box>
  );
};

export default Home;
