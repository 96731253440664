import React from 'react';
import ParticleBackground from './ParticleBackground';
import { Box, Button, Container, Typography } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import theme from '../../theme';


const HeroSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '50vh',
    width: '100vw',  // Set the width to 100% of the viewport width
    position: 'relative', // Ensure it stays within the viewport
    left: '50%',  // Position from the left edge
    transform: 'translateX(-50%)',  // Center the HeroSection horizontally
    background: `linear-gradient(135deg, ${theme.palette.background.default}, ${theme.palette.gradient.primary}, ${theme.palette.background.default})`,
    color: '#fff',
    textAlign: 'center',
    padding: theme.spacing(8, 2),  // Padding on top and bottom
    boxSizing: 'border-box',  // Include padding in the element's total width and height
    overflow: 'hidden', // Prevent any content from overflowing
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 2),
    },
  }));

  const SectionTitle = styled(Typography)(({ theme }) => ({
    fontSize: '2rem',
    fontWeight: 600,
    color: '#fff',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      marginBottom: theme.spacing(1),
    },
  }));
  
const MissionStatement = styled(Typography)(({ theme }) => ({
    fontSize: '1.5rem',
    color: '#fff',
    maxWidth: '800px',
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
        margin: theme.spacing(1, 0),
    },
    }));

const AboutContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(6, 2),
    backgroundColor: theme.palette.featured.transient,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    marginTop: theme.spacing(4),
    maxWidth: 'md',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(4, 1),
    },
    }));

const SubsectionTitle = styled(Typography)(({ theme }) => ({
    fontSize: '2rem',
    fontWeight: 600,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
    },
    }));

const HeroAbout = () => {
    return (
        <Box bgcolor={theme.palette.background.default}>
        <ParticleBackground />
        <HeroSection>
          <SectionTitle>Our Mission is to</SectionTitle>
          <SectionTitle>Advance AI's full potential.</SectionTitle>
          <MissionStatement>
            We aim to create a world where AI represents the best of humanity.
          </MissionStatement>
        </HeroSection>
        </Box>
    );
}

export default HeroAbout;