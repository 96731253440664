import React from 'react';
import { Box, CssBaseline, Typography, Grid, Card, CardContent, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import HeroSection from "../components/datasets/HeroSectionDatasets";
import StatsSection from "../components/datasets/StatsSectionDatasets";
import CtaSection from "../components/datasets/CtaSectionDatasets";
import DoctorPatient from "../media/doctor-collecting-patient-blood-sample-from-vein.svg";
import InclusionPeople from "../media/inclusion-and-diverse-people.svg";
import BlackWoman from "../media/confident-black-woman-strides-in-fashionable-attire.svg";
import CrowdCommunity from "../media/crowd-community.svg";
import Crowd from "../media/crowd.svg";
import DatabricksMarketplace from "../media/Databricks-Marketplace-partner.svg";
import { useTheme } from '@mui/material/styles';

const datasets = [
  {
    title: "500,000 Unique and Diverse Faces for AI",
    description: "Optimized for Facial Recognition Evaluation powered by our CulturePulseIQ technology.",
    image: InclusionPeople,
    type: "Image",
  },
  {
    title: "1,000 Multi-Angle and Unique Faces",
    description: "Left, Right, Front with Different Lighting Scenarios Optimized for Facial Recognition.",
    image: CrowdCommunity,
    type: "Image",
  },
  {
    title: "Black Women Dataset",
    description: "Comprehensive Image Set with Self-Reported Metadata such as Skin Tone and more.",
    image: BlackWoman,
    type: "Image",
  },
  {
    title: "250 Hours of Diverse Health Audio",
    description: "Optimized for ASR model evaluation in AI healthcare scenarios.",
    image: DoctorPatient,
    type: "Audio",
  },
  {
    title: "100 Million Diverse Personas",
    description: "Culturally Relevant for AI Training and Evaluation powered by CulturePulseIQ technology.",
    image: Crowd,
    type: "Tables",
  }
];

const DatasetsPage = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: '100vh',  // Ensure full height of viewport
        width: '100%',
      }}
    >
      <CssBaseline />
      <HeroSection
        bgColor="default"
        size="medium"
        title="Data You Can Trust"
        subtitle="Compliant datasets, ready for your next project."
        buttonText="Request Access"
        buttonColor="primary"
        buttonPath="/contact"
      />

      <StatsSection
        bgColor="default"
        size="medium"
        sx={{ marginTop: '3rem', paddingBottom: '4rem' }}
      />

      <Typography 
        variant="h4" 
        align="center" 
        gutterBottom 
        sx={{ 
          marginTop: '8rem',  // Increased from 5rem to 8rem
          marginBottom: '4rem', 
          color: 'text.secondary',
        }}
      >
        AI-Ready Datasets
      </Typography>

      <Grid container spacing={4} justifyContent="center" sx={{ padding: '0 2rem' }}>
        {datasets.map((dataset, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ 
              height: '100%', 
              backgroundColor: theme.palette.featured.transient, 
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between', // Ensures button is at the bottom
              alignItems: 'center',
            }}>
              <Box sx={{ width: '100%', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1rem' }}>
                <img src={dataset.image} alt={dataset.title} style={{ width: '50%', height: '100%', objectFit: 'contain' }} />
              </Box>
              <CardContent sx={{ width: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box>
                  <Typography variant="h6" gutterBottom align="center">{dataset.title}</Typography>
                  <Typography variant="body2" color="text.secondary" align="center">{dataset.description}</Typography>
                </Box>
                <Button 
                  variant="contained" 
                  color="primary" 
                  component={RouterLink}
                  to={`/contact?message=I'm interested in the ${dataset.title} AI-ready dataset.`}
                  sx={{ 
                    marginTop: '1rem', 
                    display: 'flex', 
                    width: '100%', 
                    justifyContent: 'center', 
                    alignItems: 'center' 
                  }}
                >
                  Request Access
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box 
        sx={{ 
          my: 12, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          backgroundColor: theme.palette.featured.transient,
          borderRadius: '0px',
          padding: '3rem',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          maxWidth: '100%',
          margin: '5rem auto'
        }}
      >
        <Typography variant="h5" align="center" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '2rem' }}>
          Databricks Marketplace Partner
        </Typography>
        <Box 
          component="a"
          href="https://marketplace.databricks.com/provider/3d28060f-1726-48a7-b699-f4a84328832e/Destined"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ 
            width: '250px', 
            height: '125px', 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: "transparent",
            borderRadius: '0px',
            padding: '1rem'
          }}
        >
          <img 
            src={DatabricksMarketplace} 
            alt="Databricks Marketplace Partner" 
            style={{ 
              width: '100%', 
              height: '100%', 
              objectFit: 'contain',
              filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
            }} 
          />
        </Box>
      </Box>

      <Box sx={{ my: 4 }}>
        <CtaSection
          bgColor="transparent"
          size="medium"
          title="Ready to take your project to the next level?"
          subtitle="Let's connect."
          buttonText="Get In Touch"
          buttonColor="primary"
          buttonPath="/contact?message=I'm interested in learning more about your AI-ready datasets."
        />
      </Box>
    </Box>
  );
};

export default DatasetsPage;
