import React from 'react';
import { Typography, Container, Box, Grid, CssBaseline } from '@mui/material';
import { styled } from '@mui/system';
import Lottie from 'react-lottie';
import animationData1 from '../media/safe_world.json';
import animationData2 from '../media/pillar.json';
import founderImage from '../media/founder.jpg';
import theme from '../theme';
import HeroAbout from '../components/shared/heroAbout';

const TextBlock = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  lineHeight: 1.8,
  marginBottom: theme.spacing(4),
  color: theme.palette.text.primary,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
    textAlign: 'center', // Center text on mobile
  },
}));

const PillarTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.8rem',
  fontWeight: 700,
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.4rem',
    textAlign: 'center', // Center text on mobile
  },
}));

const PillarText = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  lineHeight: 1.6,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
    marginBottom: theme.spacing(1.5),
    textAlign: 'center', // Center text on mobile
  },
}));

const AboutContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(6, 2),
  backgroundColor: theme.palette.featured.transient,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  marginTop: theme.spacing(4),
  maxWidth: 'md',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 1),
  },
}));

const SubsectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 600,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
    textAlign: 'center', // Center text on mobile
  },
}));

const IconWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(4),
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(2),
  },
}));

const FounderImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  marginBottom: theme.spacing(2),
}));

const FounderSection = styled(Box)(({ theme }) => ({
  margin: theme.spacing(10, 5),  // Increase margin for more space on desktop
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(4, 2),
    textAlign: 'center', // Center text on mobile
  },
}));

const FounderSubsectionTitle = styled(SubsectionTitle)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const About = () => {
  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: animationData1,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: animationData2,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Box bgcolor={theme.palette.background.default}>
      <CssBaseline />
      <HeroAbout />
      <AboutContainer>
        <Grid container spacing={4} sx={{ marginTop: { xs: 4, md: 8 } }}>
          <Grid item xs={12} md={6}>
            <IconWrapper>
              <Lottie options={defaultOptions1} height={200} width={200} />
            </IconWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <SubsectionTitle>Transformative Purpose</SubsectionTitle>
            <TextBlock>
              Through accurate, balanced, and ethically sourced data, Destined AI aims to create a safe and equitable world where AI represents the best that humanity has to offer, not the worst.
            </TextBlock>
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={{ marginTop: { xs: 4, md: 8 } }}>
          <Grid item xs={12} md={6}>
            <IconWrapper>
              <Lottie options={defaultOptions2} height={200} width={200} />
            </IconWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <SubsectionTitle>Our Pillars</SubsectionTitle>

            <PillarTitle>Trust</PillarTitle>
            <PillarText>
              We believe that only with a strong foundation of trust can we build AI responsibly.
            </PillarText>

            <PillarTitle>Community</PillarTitle>
            <PillarText>
              We are dedicated to investing in our communities and ensuring technology advancements benefit communities worldwide.
            </PillarText>

            <PillarTitle>Responsibility</PillarTitle>
            <PillarText>
              We pledge to advance humanity responsibly and equitably.
            </PillarText>
          </Grid>
        </Grid>

        <FounderSection>
          <FounderSubsectionTitle>Meet the Founder</FounderSubsectionTitle>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <FounderImage src={founderImage} alt="Gerald Carter - Founder of Destined AI" />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextBlock>
                Gerald Carter founded Destined AI to help companies detect and mitigate unwanted bias in AI. He developed the company after experiencing firsthand AI’s mislabeling of diverse people with derogatory terms, its failure to decipher speech by people with US southern accents, and its struggle to detect brown skin tones. <br /><br />
                Since its inception, Destined AI has won two pitch competitions, received a grant from Microsoft and Shopify Ventures, been accepted in the Google for Startups Cloud AI program, backed by Techstars powered by JP Morgan, and generated revenue while working with and securing enterprise customers. <br /><br />
                The company has ethically sourced data, built a community, and economically empowered over 4000 data contributors.
              </TextBlock>
            </Grid>
          </Grid>
        </FounderSection>
      </AboutContainer>
    </Box>
  );
};

export default About;
