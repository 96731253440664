import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Lottie from 'react-lottie';
import animationData1 from '../../media/Data.json';
import animationData2 from '../../media/Process.json';

const FeaturesSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(8, 2),
  marginTop: 0,
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    padding: theme.spacing(12, 2),
  },
}));

const FeatureBlock = styled(Box)(({ theme }) => ({
  flex: 1,
  margin: theme.spacing(3),
  padding: theme.spacing(5),
  backgroundColor: theme.palette.featured.transient,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  textAlign: 'center',
  '&:hover': {
    boxShadow: theme.shadows[6],
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
  },
}));

const IconWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: animationData1,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const defaultOptions2 = {
  loop: true,
  autoplay: true,
  animationData: animationData2,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Features = () => (
  <FeaturesSection>
    <FeatureBlock>
      <IconWrapper>
        <Lottie options={defaultOptions1} height={150} width={150} />
      </IconWrapper>
      <Typography variant="h5" component="h3" gutterBottom>
        Pillar App
      </Typography>
      <Typography variant="body1">
        Multimodal data collection platform featuring thousands of diverse people, from people of color to rural white southerners.
      </Typography>
    </FeatureBlock>
    <FeatureBlock>
      <IconWrapper>
        <Lottie options={defaultOptions2} height={150} width={150} />
      </IconWrapper>
      <Typography variant="h5" component="h3" gutterBottom>
        datawaveIQ™
      </Typography>
      <Typography variant="body1">
        Easily turn your content into profitable AI-ready data with our datawaveIQ™ platform.
      </Typography>
    </FeatureBlock>
  </FeaturesSection>
);

export default Features;
