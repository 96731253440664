import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Section from "../shared/Section";
import SectionHeader from "../shared/SectionHeader";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const CtaSection = (props) => {
  const theme = useTheme();

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      sx={{
        padding: theme.spacing(12, 0),
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            textAlign: "center",
            padding: theme.spacing(6, 2),
            borderRadius: theme.shape.borderRadius,
            backgroundColor: "transparent",
            boxShadow: theme.shadows[3],
          }}
        >
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={3}
            sx={{
              marginBottom: theme.spacing(4),
              "& h3": {
                fontWeight: 500,
                color: theme.palette.primary.main,
              },
              "& p": {
                fontSize: "1.2rem",
                color: theme.palette.text.secondary,
              },
            }}
          />
          <Button
            component={Link}
            to={props.buttonPath}
            variant="contained"
            size="large"
            color={props.buttonColor}
            sx={{
              padding: theme.spacing(1.5, 4),
              fontSize: "1.1rem",
              fontWeight: 600,
              textTransform: "none",
              borderRadius: "0px",
              boxShadow: theme.shadows[2],
              "&:hover": {
                boxShadow: theme.shadows[4],
              },
            }}
          >
            {props.buttonText}
          </Button>
        </Box>
      </Container>
    </Section>
  );
};

export default CtaSection;
