// src/pages/Contact.js
import React from 'react';
import { Typography, Container, Box, Grid, CssBaseline } from '@mui/material';
import { styled } from '@mui/system';
import ContactForm from '../components/shared/ContactForm';
import theme from '../theme';

const PageContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(8, 2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  marginTop: theme.spacing(8),
  maxWidth: 'sm',
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(8, 4), // Added more padding on larger screens
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 700,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(2),
  textAlign: 'center',
}));

const IntroText = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(4),
  textAlign: 'center',
}));

const ContactDetails = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  textAlign: 'center',
}));

const Contact = () => {
  return (
    <PageContainer>
        <CssBaseline />
      <SectionTitle>Contact Us</SectionTitle>
      <IntroText sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: {xs: '100%', md: '50%'}, mx: 'auto' }}>
        We'd love to hear from you! Please fill out the form below, and we'll get back to you as soon as possible.
      </IntroText>
      <ContactForm />
      <ContactDetails>
        <Typography variant="body1" color="text.primary">
          Destined AI, Inc <br />
          3423 Piedmont Rd NE, Atlanta, GA 30305 <br />
          Phone: (470) 444-9950 <br />
        </Typography>
      </ContactDetails>
    </PageContainer>
  );
};

export default Contact;
