import React from 'react';
import ParticleBackground from './ParticleBackground';
import { Box, Button, Container, Typography } from '@mui/material';
import { styled, keyframes } from '@mui/system';

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const HeroSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '60vh',
  padding: theme.spacing(4),
  textAlign: 'center',
  zIndex: 1,
  position: 'relative',
  marginTop: theme.spacing(8),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(8),
  },
}));

const GradientText = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.gradient.primary}, ${theme.palette.gradient.secondary}, ${theme.palette.gradient.tertiary})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  textFillColor: 'transparent',
  animation: `${pulse} 8s infinite`,
  fontSize: '3rem',
  fontWeight: 500,
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: '#ffffff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const Hero = () => {
  return (
    <Box>
      <ParticleBackground />
      <HeroSection>
        <Container maxWidth="md">
          <GradientText variant="h1" component="h1" gutterBottom>
            Unlock The Potential of Your AI. <br />
            
          </GradientText>
          {/* <Typography variant="h5" component="h5" color="textSecondary" gutterBottom>
            Destined AI is on a mission to create a safe and equitable world where AI represents the best that humanity has to offer, not the worst.
          </Typography> */}
          <Typography variant="h5" component="h5" color="textSecondary" gutterBottom>
            Destined AI is a trusted data platform that empowers developers to build AI applications with confidence and responsibly.
          </Typography>
          <StyledButton variant="contained" href="/contact?message=I'm interested in learning more about the Destined AI platform.">
            Learn More
          </StyledButton>
        </Container>
      </HeroSection>
    </Box>
  );
};

export default Hero;
