import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getPostBySlug } from '../sanity';
import { Box, Container, Typography, CircularProgress, Button, CssBaseline } from '@mui/material';
import { styled } from '@mui/system';
import BlockContent from '@sanity/block-content-to-react';
import theme from '../theme';

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 700,
  color: '#fff',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
}));

const PostContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.featured.transient,
  padding: theme.spacing(6, 4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  marginTop: theme.spacing(8),
  maxWidth: 'md',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 2),
    marginTop: theme.spacing(4),
  },
}));

const PostTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 700,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(2),
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
    marginBottom: theme.spacing(1),
  },
}));

const PostDescription = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(4),
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
    marginBottom: theme.spacing(2),
  },
}));

const PostDate = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(2),
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
    marginBottom: theme.spacing(1),
  },
}));

const PostBody = styled(Box)(({ theme }) => ({
  fontSize: '1.1rem',
  color: theme.palette.text.primary,
  lineHeight: 1.8,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
}));

const CustomLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  fontWeight: 'bold',
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

const BackButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  alignSelf: 'center',
  textTransform: 'none',
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%', // Make button full width on mobile
  },
}));

const Post = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    getPostBySlug(slug).then(setPost);
  }, [slug]);

  if (!post) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box bgcolor={theme.palette.background.default} display="flex" flexDirection="column" minHeight="100vh">
        <CssBaseline />
      {/* Back button at the top */}
      <Link to="/thoughts" style={{ textDecoration: 'none' }}>
        <BackButton variant="contained">Back to Blog</BackButton>
      </Link>

      <PostContainer>
        <PostTitle>{post.title}</PostTitle>
        <PostDate>Published on: {new Date(post.dateCreated).toLocaleDateString()}</PostDate>
        <PostDescription>By: {post.author}</PostDescription>
        <PostDescription>{post.description}</PostDescription>
        <PostBody>
          <BlockContent
            blocks={post.body}
            serializers={{
              marks: {
                link: ({ children, mark }) => (
                  <CustomLink href={mark.href} target="_blank" rel="noopener noreferrer">
                    {children}
                  </CustomLink>
                ),
              },
            }}
          />
        </PostBody>
      </PostContainer>

      {/* Back button at the bottom */}
      <Link to="/thoughts" style={{ textDecoration: 'none', marginTop: theme.spacing(4) }}>
        <BackButton variant="contained">Back to Blog</BackButton>
      </Link>
    </Box>
  );
};

export default Post;
