// src/pages/Blog.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getPosts } from '../sanity';
import { Box, Container, Typography, Button, CssBaseline } from '@mui/material';
import { styled } from '@mui/system';
import ParticleBackground from '../components/shared/ParticleBackground';
import theme from '../theme';

const HeroSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'bottom',
  justifyContent: 'flex-end',
  minHeight: '20vh',
  width: '100vw',  // Set the width to 100% of the viewport width
  position: 'relative', // Ensure it stays within the viewport
  left: '50%',  // Position from the left edge
  transform: 'translateX(-50%)',  // Center the HeroSection horizontally
  background: `linear-gradient(135deg, ${theme.palette.background.default}, ${theme.palette.gradient.primary}, ${theme.palette.background.default})`,
  color: '#fff',
  textAlign: 'center',
  padding: theme.spacing(1, 1),
  marginBottom: 0,
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 600,
  color: '#fff',
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
    fontWeight: 500,
  },
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  minHeight: '80vh',
  padding: theme.spacing(2, 0),
  backgroundColor: theme.palette.background.default,
}));

const PostContainer = styled(Container)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: theme.spacing(2, 4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  maxWidth: 'md',
}));

const PostTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.3rem',
  fontWeight: 500,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(0),
}));

const PostDescription = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(0),
}));

const PostDate = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(0),
}));

const ReadMoreButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  color: theme.palette.primary.main,
  fontWeight: 600,
  paddingLeft: 0,
  marginBottom: theme.spacing(0),
}));

const Blog = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    getPosts().then(setPosts);
  }, []);

  return (
    <Box bgcolor={theme.palette.background.default} display="flex" flexDirection="column" minHeight="100vh">
        <CssBaseline />
      <ParticleBackground />
      <HeroSection>
        <SectionTitle>Thoughts on AI</SectionTitle>
      </HeroSection>
      <ContentContainer>
        {posts.map((post) => (
          <PostContainer key={post._id}>
            <Link to={`/thoughts/${post.slug.current}`} style={{ textDecoration: 'none' }}>
                <PostTitle>{post.title}</PostTitle>
                <PostDate>Published on: {new Date(post.dateCreated).toLocaleDateString()}</PostDate>
                <PostDescription>{post.description}</PostDescription>
              <ReadMoreButton>Read more</ReadMoreButton>
            </Link>
          </PostContainer>
        ))}
      </ContentContainer>
    </Box>
  );
};

export default Blog;
