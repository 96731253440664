import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import theme from '../../theme';
import { Link } from 'react-router-dom';
import TechstarsLogo from '../../media/Techstars_Logo_Primary_Black.png';

import NvidiaLogo from '../../media/nvidia-inception-program-badge-rgb-for-screen.svg';
import NvidiaLogoDark from '../../media/nvidia-inception-program-badge-rgb-1c-blk-for-screen.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const emailUser = 'sales';
  const emailDomain = 'destined.ai';
  const email = `${emailUser}@${emailDomain}`;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: theme.palette.background.footer,
        width: '100%',
        py: 4,
        px: { xs: 2, md: 10 },
      }}
    >
      <Grid container spacing={4} alignItems="flex-start" justifyContent="space-between">
        {/* Address Section */}
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" color="text.primary" gutterBottom align={isMobile ? 'center' : 'left'}>
            Destined AI, Inc <br />
            3423 Piedmont Rd NE, <br />
            Atlanta, GA 30305
          </Typography>
        </Grid>

        {/* Logos Section */}
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
            <Typography variant="body1" color="text.primary" sx={{ mb: 1 }}>
              Backed by
            </Typography>
            <Box sx={{ 
              backgroundColor: '#fff', 
              borderRadius: '0px', 
              padding: '8px', 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              width: '140px', 
              height: '70px' 
            }}>
              <img src={TechstarsLogo} alt="Techstars Logo" style={{ width: '100%', height: 'auto', objectFit: 'contain' }} />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
            <Typography variant="body1" color="text.primary" sx={{ mb: 1 }}>
              Member of
            </Typography>
            <Box sx={{ 
              backgroundColor: isDarkMode ? '#fff' : 'transparent', 
              borderRadius: '0px', 
              padding: '8px', 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              width: '160px', 
              height: '80px' 
            }}>
              {isDarkMode ? (
                <img src={NvidiaLogo} alt="Nvidia Logo" style={{ width: '100%', height: 'auto', objectFit: 'contain' }} />
              ) : (
                <img src={NvidiaLogoDark} alt="Nvidia Logo Dark" style={{ width: '100%', height: 'auto', objectFit: 'contain' }} />
              )}
            </Box>
          </Box>
          <Link to="/privacy" style={{ textDecoration: 'none' }}>
            <Typography variant="body2" color="primary.main" sx={{ mt: 1 }}>
              Privacy Policy
            </Typography>
          </Link>
        </Grid>

        {/* Contact Section */}
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" color="text.primary" gutterBottom align={isMobile ? 'center' : 'right'}>
            Contact Us: <br />
            <a href={`mailto:${email}`} style={{ color: theme.palette.primary.main, textDecoration: 'none' }}>
              {emailUser}[at]{emailDomain}
            </a>
            <br />
            (470) 444-9950
          </Typography>
        </Grid>
      </Grid>

      {/* Footer Bottom */}
      <Box sx={{ py: 2, mt: 4, borderTop: `1px solid ${theme.palette.divider}` }}>
        <Typography variant="body2" color="text.secondary" align="center">
          © {currentYear} Destined AI, Inc. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
