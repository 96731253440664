import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Section from "../shared/Section";
import { styled } from "@mui/material/styles";

const StyledBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.featured.transient,
  boxShadow: theme.shadows[3],
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: theme.shadows[6],
  },
}));

const StatTypography = styled(Typography)(({ theme }) => ({
  fontSize: "2.5rem",
  fontWeight: 700,
  marginBottom: theme.spacing(1),
  background: `linear-gradient(45deg, ${theme.palette.gradient.primary}, ${theme.palette.gradient.secondary})`,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
}));

function StatsSection(props) {
  const items = [
    {
      title: "Total Data Points",
      stat: "100M+",
      description: "Diverse and unique data points across personas, images, and audio."
    },
    {
      title: "AI-Ready Faces",
      stat: "500,000",
      description: "Unique and diverse faces optimized for facial recognition evaluation."
    },
    {
      title: "Culturally Relevant Personas",
      stat: "100M",
      description: "Diverse personas designed for culturally robust AI evaluation."
    },
    {
      title: "Voice Recordings",
      stat: "250+ Hours",
      description: "Diverse US voice recordings for ASR and healthcare AI models."
    },
    {
      title: "New Data",
      stat: "Unlimited",
      description: "Continuously updated with new data to ensure accuracy and relevance."
    },
    {
      title: "Contributor Community",
      stat: "4,500+",
      description: "A growing contributor base for ethically sourced, evolving data."
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      sx={{ paddingTop: '4rem', paddingBottom: '4rem' }}
    >
      <Container maxWidth="lg">
        <Grid container justifyContent="center" spacing={4}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <StyledBox>
                <Typography variant="h6" gutterBottom>{item.title}</Typography>
                <StatTypography variant="h3">{item.stat}</StatTypography>
                <Typography variant="body2" color="text.secondary">{item.description}</Typography>
              </StyledBox>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default StatsSection;
