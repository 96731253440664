import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Privacy from './pages/Privacy';
import Layout from './components/shared/Layout'; 
import Blog from './pages/Blog';
import Post from './pages/Post';
import Contact from './pages/Contact';
import NotFoundPage from './pages/404';
import Datasets from './pages/Datasets';

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Datasets />} />
          <Route path="/platform" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/thoughts" element={<Blog />} />
          <Route path="/thoughts/:slug" element={<Post />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/datasets" element={<Datasets />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
