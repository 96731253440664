import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import { styled, keyframes } from "@mui/material/styles";
import ParticleBackground from '../shared/ParticleBackground';

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const HeroSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '60vh',
  padding: theme.spacing(4),
  textAlign: 'center',
  zIndex: 1,
  position: 'relative',
  marginTop: theme.spacing(8),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(8),
  },
}));

const GradientText = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.gradient.primary}, ${theme.palette.gradient.secondary}, ${theme.palette.gradient.tertiary})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  textFillColor: 'transparent',
  animation: `${pulse} 8s infinite`,
  fontSize: '3rem',
  fontWeight: 500,
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: '#ffffff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

function HeroSectionComponent({ title, subtitle, buttonText, buttonPath }) {
  return (
    <Box>
      <HeroSection>
        <ParticleBackground />
        <Container maxWidth="md">
          <GradientText variant="h1" component="h1" gutterBottom>
            {title}
          </GradientText>
          <Typography variant="h5" component="h5" color="textSecondary" gutterBottom>
            {subtitle}
          </Typography>
          {/* <StyledButton variant="contained" href={buttonPath}>
            {buttonText}
          </StyledButton> */}
        </Container>
      </HeroSection>
    </Box>
  );
}

export default HeroSectionComponent;
