// src/sanity.js
import { createClient } from '@sanity/client';

export const client = createClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID, 
  dataset: process.env.REACT_APP_SANITY_DATASET || 'production',
  useCdn: false, 
  apiVersion: '2024-08-22', 
});

// Function to fetch posts, sorted by dateCreated in descending order
export async function getPosts() {
    const query = `*[_type == "post"] | order(dateCreated desc) {
      title, 
      slug, 
      description, 
      body, 
      _id,
      author,
      dateCreated
    }`;
    const posts = await client.fetch(query);
    return posts;
}

// Function to fetch a single post by slug
export async function getPostBySlug(slug) {
    const query = `*[_type == "post" && slug.current == $slug][0]{
      title, 
      slug, 
      description, 
      body, 
      _id, 
      author,
      dateCreated
    }`;
    const post = await client.fetch(query, { slug });
    return post;
}
