import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const StyledHeader = styled(Box)(({ theme }) => ({
  "&:not(:last-child)": {
    marginBottom: "2rem",
  },
}));

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  maxWidth: 700,
  display: "inline-block",
}));

function SectionHeader({ subtitle, title, size, className, ...otherProps }) {
  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <StyledHeader
      component="header"
      className={className}
      {...otherProps}
    >
      {title && (
        <Typography
          variant={`h${size}`}
          gutterBottom={subtitle ? true : false}
        >
          {title}
        </Typography>
      )}

      {subtitle && (
        <StyledSubtitle variant="subtitle1">
          {subtitle}
        </StyledSubtitle>
      )}
    </StyledHeader>
  );
}

export default SectionHeader;
