import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { emphasize } from "@mui/material/styles";
import { capitalize } from "@mui/material/utils";
import BackgroundImage from "./BackgroundImage";

const StyledSection = styled('section')(({ theme }) => ({
  position: "relative",
  // Ensure child <Container> is above background
  // image (if one is set with the bgImage prop).
  "& > .MuiContainer-root": {
    position: "relative",
    zIndex: 1,
  },
  // Add a pseudo-element for the background image
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 0,
  },

  // Create color classes that set background color and determine
  // text color and dividing border automatically based on background color.
  // Adds the keys colorDefault, colorLight, etc
  ...[
    ["default", theme.palette.background.default],
    ["light", emphasize(theme.palette.background.default, 0.03)],
    ["primary", theme.palette.primary.main],
    ["secondary", theme.palette.secondary.main],
  ].reduce((acc, [name, value]) => {
    acc[`&.color${capitalize(name)}`] = {
      backgroundColor: value,
      // Ensure text is legible on background
      color: theme.palette.getContrastText(value),
      // Sibling selector that adds a top border if section above
      // has the same color class.
      // We use emphasize to compute border based on background color
      // and make sure it's always lightly visible.
      [`& + &`]: {
        borderTop: `1px solid ${emphasize(value, 0.09)}`,
      },
    };
    return acc;
  }, {}),

  "&.colorInherit": {
    color: "inherit",
  },

  "&.colorTransparent": {
    backgroundColor: "transparent",
    color: "inherit",
  },
}));

const Section = React.forwardRef(function Section(props, ref) {
  const {
    bgColor = "default",
    bgImage,
    bgImageOpacity,
    size = "normal",
    className,
    children,
    ...otherProps
  } = props;

  // Get MUI responsive size object based
  // on size prop (normal, medium, large, auto)
  const verticalPadding = {
    normal: { xs: 6 },
    medium: { xs: 6, sm: 10 },
    large: { xs: 6, sm: 20 },
    auto: 0,
  }[size];

  return (
    <StyledSection
      ref={ref}
      py={verticalPadding}
      className={`color${capitalize(bgColor)}${className ? ` ${className}` : ""}`}
      {...otherProps}
    >
      {bgImage && <BackgroundImage image={bgImage} opacity={bgImageOpacity} />}
      {children}
    </StyledSection>
  );
});

export default Section;
